import { Icon, Tag, Text, Tooltip } from '@zenchef/ds-react'
import { css } from '@zenchef/styled-system/css'
import { HStack, Stack } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'

import { Well } from '@/components'
import MoreLessOffer from '@/components/redesign/offers/MoreLessOffer'
import OfferImage from '@/components/redesign/offers/OfferImage'
import OfferTag, { OfferTagData } from '@/components/redesign/offers/OfferTag'
import { Bookings } from '@/types/types'
import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'

const isOfferSelected = (
  offer: Bookings.SelectedOffer | Bookings.HighlightedOffer
): offer is Bookings.SelectedOffer => {
  return 'has_specific_rooms' in offer
}

export interface BaseOfferCardProps {
  offer: Bookings.HighlightedOffer | Bookings.SelectedOffer
  isAvailable?: boolean
  offerTags?: OfferTagData[]
  offerName: string
  offerDescription?: string
  offerBookableRooms?: number[]
  hasRoomIncompatibility?: boolean
  roomsById?: Record<Bookings.Room['id'], Bookings.Room>
  hasStockTable?: boolean
  onCardClick?: (offer: Bookings.HighlightedOffer | Bookings.SelectedOffer) => void
  showMoreLess?: boolean
  showTooltip?: boolean
}

const wrapperCss = css({
  flexWrap: 'wrap',
  border: 'none',
  boxShadow: 'effect.shadow.subtle-bottom',
  alignItems: 'stretch',
  maxWidth: 'calc(100vw - {spacing.padding.4})',
  width: {
    base: '304px',
    _containerS: '280px'
  },
  transition: 'transform 0.1s ease-in-out',
  _hover: {
    '&[data-clickable="true"]': {
      '&:not([aria-disabled="true"])': {
        cursor: 'pointer',
        boxShadow: 'effect.shadow.bolder-bottom',
        transform: 'translateY(-1px)',
        '&:not(:has(.less-button:hover)) .more-button, .add-button': {
          _enabled: {
            filter: {
              _dark: 'brightness(0.95)',
              _light: 'brightness(1.05)'
            }
          }
        }
      },
      _disabled: {
        cursor: 'not-allowed'
      }
    }
  },
  _active: {
    transition: 'none!',
    boxShadow: 'effect.shadow.subtle-bottom!',
    transform: 'translateY(0)!'
  }
})

const BaseOfferCard = ({
  offer,
  isAvailable,
  offerTags,
  offerName,
  offerDescription,
  offerBookableRooms = [],
  hasRoomIncompatibility = false,
  hasStockTable = false,
  roomsById,
  onCardClick,
  showMoreLess = false,
  showTooltip = true
}: BaseOfferCardProps) => {
  const { translateField } = useTranslation()

  return (
    <Well
      key={offer.id}
      aria-disabled={!!onCardClick && !isAvailable}
      data-clickable={!!onCardClick}
      className={wrapperCss}
      onClick={() => {
        if (onCardClick && isAvailable) {
          onCardClick(offer)
        }
      }}>
      <Stack
        justifyContent='flex-start'
        alignItems='flex-start'
        width='100%'
        filter={hasRoomIncompatibility ? 'grayscale(1)' : undefined}
        opacity={hasRoomIncompatibility ? 0.6 : undefined}
        gap='gap.0'>
        <OfferImage pictureUrl={isOfferSelected(offer) ? offer.picture_url : offer.picture?.url} rounded>
          {!!offerTags?.length && (
            <HStack
              position='absolute'
              bottom='padding.2'
              left='padding.4'
              right='padding.3'
              gap='gap.1'
              flexWrap='wrap'>
              {offerTags.map((tagData) => (
                <OfferTag {...tagData} key={tagData.tag} />
              ))}
            </HStack>
          )}
          {isOfferSelected(offer) && showMoreLess ? (
            <MoreLessOffer
              big
              offer={offer}
              testId='offer-card'
              position='absolute'
              top='padding.3'
              right='padding.3'
              boxShadow='0px 1px 2px 0px token(colors.blackLevel.15)'
              isSelectable={isAvailable}
            />
          ) : null}
        </OfferImage>

        <Stack
          px='padding.4'
          py={offerDescription ? 'padding.4' : 'padding.3'}
          pt={offerDescription ? 'padding.2' : undefined}
          gap='gap.0'
          flexGrow={2}
          width='100%'>
          <HStack justify='space-between'>
            <Text textStyle='title.m' color='content.neutral.base.bold' lineClamp={2}>
              {offerName}
            </Text>
            {showTooltip ? (
              <Tooltip
                title={offerName}
                description={offerDescription ? <Text {...safelySetInnerHTML(offerDescription)} /> : null}
                placement='bottom-center'>
                <Icon name='info-circle' fontSize='20px' color='content.neutral.base.bold' />
              </Tooltip>
            ) : null}
          </HStack>

          {offerDescription ? (
            <Text
              textStyle='paragraph.m.regular'
              color='content.neutral.base.subtle'
              {...safelySetInnerHTML(offerDescription)}
              overflow='hidden'
              textOverflow='ellipsis'
              display='-webkit-box'
              lineClamp={2}
              justifySelf='flex-start'
              boxOrient='vertical'
              flexGrow={1}
            />
          ) : null}
          {'has_specific_rooms' in offer && hasStockTable && roomsById && (
            <>
              <HStack
                gap='unset'
                pt='padding.2'
                mt='gap.2'
                columnGap='gap.2'
                rowGap='gap.0'
                borderTop='m'
                borderColor='border.neutral.subtle'
                alignItems='center'
                flexWrap='wrap'
                color='content.brand.subtle'>
                <Icon name='chair' fontSize={token('sizes.xs')} />
                {offerBookableRooms.map((roomId) => {
                  return (
                    <Text textStyle='title.s' key={roomId}>
                      {translateField(roomsById[roomId]?.name_translations)}
                    </Text>
                  )
                })}
              </HStack>
            </>
          )}
        </Stack>
      </Stack>
    </Well>
  )
}

export default BaseOfferCard
