import { Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react'

import SelectableItem from '@/components/redesign/common/SelectableItem'
import useAnnotateFilters from '@/components/redesign/filters/useAnnotateFilters'
import HighlightedExperienceBadge from '@/components/redesign/offers/HighlightedExperiences/HighlightedExperienceBadge'
import { Bookings } from '@/types/types'
import { useTranslation } from '@/utils/hooks'

interface AreaInputProps {
  isRequired: boolean
  options: Bookings.Room[]
  selectedValue: number | null
  onSelectValue: (area: { id: number | null; name: string }) => void
}

const AreaInput = observer(({ options, selectedValue, onSelectValue, isRequired }: AreaInputProps): JSX.Element => {
  const { t, translateField } = useTranslation()
  const { isRoomIdCompatibleWithSelectedHighlightedOffer } = useAnnotateFilters()

  const isAnnotatedWithoutRoomSelection = options.some((room) =>
    isRoomIdCompatibleWithSelectedHighlightedOffer(room.id)
  )

  return (
    <Stack gap='gap.1' width='100%'>
      {isRequired ? null : (
        <SelectableItem
          onClick={() => onSelectValue({ id: null, name: 'no_seating_preference' })}
          selected={selectedValue === null}
          testId='no-preference'
          position='relative'>
          {t('no_seating_preference')}
          {isAnnotatedWithoutRoomSelection ? (
            <HighlightedExperienceBadge position='absolute' top='-1px' right='-1px' />
          ) : (
            ''
          )}
        </SelectableItem>
      )}
      {options.map((room) => {
        const isAnnotated = isRoomIdCompatibleWithSelectedHighlightedOffer(room.id)

        return (
          <SelectableItem
            key={room.id}
            selected={room.id === selectedValue}
            testId={`room-${room.id}`}
            onClick={() => onSelectValue({ id: room.id, name: room.name })}
            position='relative'>
            {translateField(room.name_translations)}
            {isAnnotated ? <HighlightedExperienceBadge position='absolute' top='-1px' right='-1px' /> : ''}
          </SelectableItem>
        )
      })}
    </Stack>
  )
})

export default AreaInput
