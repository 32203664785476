import { Icon, NumberInput } from '@zenchef/ds-react'
import { Flex, Stack, Wrap } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react'

import Hr from '@/components/redesign/common/Hr'
import SelectableItem from '@/components/redesign/common/SelectableItem'
import useAnnotateFilters from '@/components/redesign/filters/useAnnotateFilters'
import HighlightedExperienceBadge from '@/components/redesign/offers/HighlightedExperiences/HighlightedExperienceBadge'
import { rangeArray } from '@/utils/helpers'
import { useTranslation } from '@/utils/hooks'

interface GuestInputProps {
  min: number
  max: number
  selectedValue?: number
  onSelectValue: (value: number, isMoreGuest?: boolean) => void
}

const MAX_NUMBER_OF_INPUTS = 10

const GuestInput = observer(({ min, max, selectedValue, onSelectValue }: GuestInputProps): JSX.Element => {
  const { t } = useTranslation()
  const RANGE_ARRAY = rangeArray(min, max, MAX_NUMBER_OF_INPUTS)
  const hasMoreGuestOption = max - min >= MAX_NUMBER_OF_INPUTS
  const indexOfSelectedValue = selectedValue === undefined ? -1 : RANGE_ARRAY.indexOf(selectedValue)
  const selectedValueIsMoreGuest = hasMoreGuestOption
    ? indexOfSelectedValue === -1 || indexOfSelectedValue === MAX_NUMBER_OF_INPUTS - 1
    : false

  const { isPaxCompatibleWithSelectedHighlightedOffer } = useAnnotateFilters()
  return (
    <Flex direction='column' align='center' height='100%' width='100%'>
      <Wrap gap='gap.0,5' justify='center'>
        {RANGE_ARRAY.map((nbGuests, index) => {
          const isMoreGuestOption = hasMoreGuestOption ? index === MAX_NUMBER_OF_INPUTS - 1 : false
          const isSelected = isMoreGuestOption ? selectedValueIsMoreGuest : nbGuests === selectedValue
          const label = isMoreGuestOption ? '+' : nbGuests

          const isAnnotated = isPaxCompatibleWithSelectedHighlightedOffer(nbGuests)

          return (
            <SelectableItem
              squared
              key={nbGuests}
              selected={isSelected}
              testId={`${label}guest`}
              onClick={() => {
                onSelectValue(nbGuests, isMoreGuestOption)
              }}
              position='relative'>
              {isMoreGuestOption ? <Icon name='plus' /> : label}
              {isAnnotated ? <HighlightedExperienceBadge position='absolute' top='-5px' right='-5px' /> : ''}
            </SelectableItem>
          )
        })}
      </Wrap>
      {hasMoreGuestOption && selectedValueIsMoreGuest && (
        <Stack width='100%' gap='gap.4' marginTop='margin.4'>
          <Hr
            textTransform='uppercase'
            textStyle='title.xs'
            borderColor='border.neutral-on-brand.subtle'
            color='content.neutral.subtle'>
            {t('more')}
          </Hr>
          <NumberInput
            value={selectedValue}
            onValueChange={(value) => onSelectValue(value, true)}
            testId='more-guest'
            min={min}
            max={Math.min(max, 100)}
            size='medium'
            reversed
          />
        </Stack>
      )}
    </Flex>
  )
})

export default GuestInput
