import { Button } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { WishFilter } from '@/types/types'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

import useSearchBookingWish, { NextPageUrl } from './useSearchBookingWish'

interface SearchBookingWishButtonProps {
  currentAccordionItem: WishFilter | undefined
  setCurrentAccordionItem: (item) => void
}

const SearchBookingWishButton = observer(
  ({ setCurrentAccordionItem, currentAccordionItem }: SearchBookingWishButtonProps) => {
    const { t } = useTranslation()
    const { goToNextPage, nextPageUrl, isRoomMissing, buttonLabelKey, updateSelection, accordionItemToOpen } =
      useSearchBookingWish()
    const { appStore } = useContext(StoresContext)
    const { isInUpdateFlow, booking, shouldPrechargeOrPrepayShift } = appStore.state

    const cannotProceedWithModification =
      isInUpdateFlow && (!booking?.is_modifiable_by_enduser || shouldPrechargeOrPrepayShift)
    const cannotProceedWithBooking = !nextPageUrl || isRoomMissing

    const disabled =
      cannotProceedWithModification ||
      (cannotProceedWithBooking && (accordionItemToOpen === undefined || accordionItemToOpen === currentAccordionItem))

    const handleClick = () => {
      if (cannotProceedWithBooking) {
        setCurrentAccordionItem(accordionItemToOpen)
      } else {
        updateSelection()
        goToNextPage()
      }
    }

    return (
      <Button
        hierarchy='brand-reversed-bold'
        size='medium'
        disabled={disabled}
        onClick={handleClick}
        testId={buttonLabelKey}
        width='100%'>
        {t(buttonLabelKey)}
      </Button>
    )
  }
)

export default SearchBookingWishButton
