import { Icon } from '@zenchef/ds-react'
import { StyleProps } from '@zenchef/styled-system/types'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { Button } from '@/components/redesign/common/Button'
import { ButtonGroupVariantProps } from '@/components/redesign/common/ButtonGroup'
import MoreLessInput from '@/components/redesign/common/MoreLessInput'
import { Bookings } from '@/types/types'
import StoresContext from '@/utils/StoresContext'

type MoreLessOfferProps = TestingAttributes & {
  offer: Bookings.SelectedOffer
  big?: boolean
  isSelectable?: boolean
} & Omit<StyleProps, keyof NonNullable<ButtonGroupVariantProps>>

const MoreLessOffer = observer(({ offer, testId, big, isSelectable, ...props }: MoreLessOfferProps) => {
  const { appStore } = useContext(StoresContext)
  const { wish, totalCountLimitedToPaxWishOffers, selectedSlot } = appStore.state
  const wishOffer = wish.offers.find((o) => o.offer_id === offer.id)
  const slotOffer = selectedSlot?.offers.find((o) => o.id === offer.id)

  if (!slotOffer) {
    throw new Error('unsupported use of MoreLessOffer component')
  }

  const { addOffer } = appStore

  if (!wishOffer) {
    return (
      <Button
        testId='add-to-cart'
        visual={big ? 'solid' : 'transparent'}
        shape='rounded'
        size='small'
        onClick={(e) => {
          e.stopPropagation()
          addOffer(offer.id)
        }}
        disabled={!isSelectable}
        className='add-button'
        {...props}>
        <Icon name='plus' fontSize='18px' />
      </Button>
    )
  }

  const isIncrementButtonDisabled = () => {
    const canIncrementIsLimitedToPaxOffer =
      slotOffer.config.is_limited_to_pax && wish.pax <= totalCountLimitedToPaxWishOffers

    return wishOffer.count >= slotOffer.stock || slotOffer.config?.is_same_for_all || canIncrementIsLimitedToPaxOffer
  }

  return (
    <MoreLessInput
      big={big}
      moreDisabled={isIncrementButtonDisabled()}
      value={wishOffer.count}
      onMore={() => addOffer(offer.id)}
      onLess={() => {
        if (slotOffer.config?.is_same_for_all) {
          appStore.removeOffer(offer.id)
        } else if (wishOffer.count === 1) {
          appStore.removeOffer(offer.id)
        } else {
          wishOffer.count = Math.max(0, wishOffer.count - 1)
        }
      }}
      testId={`${testId}-offer-${offer.id}`}
      {...props}
    />
  )
})

MoreLessOffer.displayName = 'MoreLessOffer'

export default MoreLessOffer
