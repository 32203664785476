import { Text } from '@zenchef/ds-react'
import { HStack, Stack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react'
import { useContext } from 'react'

import HighlightedExperiencesCarousel from '@/components/redesign/offers/HighlightedExperiences/HighlightedExperiencesCarousel'
import OfferImage from '@/components/redesign/offers/OfferImage'
import OfferTag, { OfferTagData } from '@/components/redesign/offers/OfferTag'
import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

const HighlightedExperiencesSection = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { t, translateField } = useTranslation()
  const { selectedHighlightedOffer, offerUnitPriceFormatted } = appStore.state

  const getOfferTags = (): OfferTagData[] => {
    const tags: OfferTagData[] = []

    if (!selectedHighlightedOffer) return tags

    if (selectedHighlightedOffer.charge_per_guests) {
      tags.push({
        colorTheme: 'brand',
        tag: `${offerUnitPriceFormatted(selectedHighlightedOffer)}/${t('pax', { count: 1 })}`
      })
      if (selectedHighlightedOffer.has_prepayment) {
        tags.push({
          colorTheme: 'brand',
          tag: t('offers.has_prepayment'),
          tooltipTitle: t('offers.has_prepayment_disclaimer')
        })
      }
    }
    return tags
  }
  const offerTags = getOfferTags()

  if (selectedHighlightedOffer) {
    return (
      <Stack gap='gap.4' mt='-margin.4' maxH='50vh' overflow='auto'>
        <OfferImage pictureUrl={selectedHighlightedOffer?.picture?.url} height='240px'>
          {!!offerTags?.length && (
            <HStack
              position='absolute'
              bottom='padding.2'
              left='padding.4'
              right='padding.3'
              gap='gap.1'
              flexWrap='wrap'>
              {offerTags.map((tagData) => (
                <OfferTag {...tagData} key={tagData.tag} />
              ))}
            </HStack>
          )}
        </OfferImage>
        <Stack gap='gap.4' px='padding.4' pb='padding.6'>
          <Text textStyle='title.xl' color='content.neutral.bold'>
            {translateField(selectedHighlightedOffer.name_translations)}
          </Text>
          <Text
            textStyle='paragraph.l.regular'
            color='content.neutral.subtle'
            {...safelySetInnerHTML(translateField(selectedHighlightedOffer.description))}
          />
        </Stack>
      </Stack>
    )
  }
  return <HighlightedExperiencesCarousel />
})

export default HighlightedExperiencesSection
