export const stayScrolledDown = (delay = 500) => {
  // scroll to bottom [data-overlayscrollbars-contents]
  const scrollable = document.querySelector('[data-overlayscrollbars-contents]')!

  let animationId: number | null = null
  let previousDelta: number = scrollable.scrollHeight - scrollable.clientHeight - scrollable.scrollTop
  const easingFactor = 0.9

  const scrollDownIndefinitely = () => {
    animationId = requestAnimationFrame(() => {
      scrollable.scrollTo({
        top: scrollable.scrollHeight - scrollable.clientHeight - previousDelta * easingFactor
      })
      previousDelta = scrollable.scrollHeight - scrollable.clientHeight - scrollable.scrollTop
      scrollDownIndefinitely()
    })
  }
  scrollDownIndefinitely()

  // Detect user interaction
  function handleUserInteraction() {
    stopScrolling()
  }

  window.addEventListener('wheel', handleUserInteraction, { passive: true })
  window.addEventListener('touchstart', handleUserInteraction, { passive: true })
  window.addEventListener('keydown', handleUserInteraction) // For arrow keys, page up/down, etc.

  const stopScrolling = () => {
    if (animationId) {
      cancelAnimationFrame(animationId) // Stop the loop
      animationId = null // Reset the ID
    }
    window.removeEventListener('wheel', handleUserInteraction)
    window.removeEventListener('touchstart', handleUserInteraction)
    window.removeEventListener('keydown', handleUserInteraction)
  }

  setTimeout(() => {
    stopScrolling()
  }, delay)
}
