import { Icon } from '@zenchef/ds-react'
import { css, cx } from '@zenchef/styled-system/css'
import { splitCssProps } from '@zenchef/styled-system/jsx'

import { Button, buttonStyle } from '@/components/redesign/common/Button'
import ButtonGroup, { ButtonGroupProps, useButtonGroupContext } from '@/components/redesign/common/ButtonGroup'

type MoreLessInputProps = TestingAttributes & {
  value: number
  onMore: () => void
  onLess: () => void
  moreDisabled?: boolean
  lessDisabled?: boolean
  big?: boolean
} & ButtonGroupProps

const SMALL_SIZE = '20px'
const BIG_SIZE = '32px'

const MoreLessInput = ({
  testId,
  lessDisabled,
  onLess,
  value,
  moreDisabled,
  onMore,
  big,
  ...props
}: MoreLessInputProps) => {
  const [cssProps] = splitCssProps(props)
  const visual = props.visual ?? big ? 'solid' : 'transparent'
  const size = big ? BIG_SIZE : SMALL_SIZE

  return (
    <ButtonGroup {...cssProps} alignItems='center' testId={`edit-count-${testId}`} visual={visual} rounded>
      <Button
        testId={`decrement-${testId}`}
        disabled={lessDisabled}
        height={size}
        width={size}
        onClick={(e) => {
          e.stopPropagation()
          onLess?.()
        }}
        display='flex'
        alignItems='center'
        className='less-button'
        justifyContent='center'>
        <Icon name='minus' fontSize={big ? '18' : '14'} />
      </Button>
      <ValueSpan value={value} size={size} />
      <Button
        testId={`increment-${testId}`}
        disabled={moreDisabled}
        height={size}
        width={size}
        onClick={(e) => {
          e.stopPropagation()
          onMore?.()
        }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className='more-button'>
        <Icon name='plus' fontSize={big ? '18' : '14'} />
      </Button>
    </ButtonGroup>
  )
}

interface ValueSpanProps {
  value: number
  size: string
}

const ValueSpan = ({ value, size }: ValueSpanProps) => {
  const { className: classNameFromContext, ...restPropsFromContext } = useButtonGroupContext()
  return (
    <span
      className={cx(
        buttonStyle({ ...restPropsFromContext }),
        classNameFromContext,
        css({ height: size, width: size })
      )}>
      &nbsp;
      {value}&nbsp;
    </span>
  )
}

export default MoreLessInput
