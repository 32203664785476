import { useEffect, useState } from 'react'

import isImageValid from '@/utils/isImageValid'

export default function useOfferImage(pictureUrl: string | null | undefined): {
  backgroundStyle: string | undefined
  shouldDisplayPlaceholderImg: boolean
} {
  const [isPictureUrlValid, setIsPictureUrlValid] = useState<boolean | undefined>(undefined)

  // Modify the image URL to point to the squared version by inserting '.csquarred' before the file extension
  // For example, 'image.jpg' becomes 'image.csquarred.jpg'
  const formattedPictureUrl = pictureUrl?.replace(/(\.[a-z]+)$/i, '.csquarred$1') ?? undefined

  useEffect(() => {
    if (formattedPictureUrl) {
      isImageValid(formattedPictureUrl).then((isValid) => {
        setIsPictureUrlValid(isValid)
      })
    }
  }, [formattedPictureUrl, pictureUrl])

  const backgroundStyle = formattedPictureUrl ? `url(${formattedPictureUrl})` : undefined

  const shouldDisplayPlaceholderImg = !backgroundStyle || !isPictureUrlValid

  return {
    backgroundStyle,
    shouldDisplayPlaceholderImg
  }
}
