import { Tag, Tooltip } from '@zenchef/ds-react'

export interface OfferTagData {
  colorTheme: 'brand' | 'neutral'
  tag: string
  tooltipTitle?: string
  tooltipDescription?: string
}

const OfferTag = ({ colorTheme, tag, tooltipTitle, tooltipDescription }: OfferTagData) => {
  const TagElement = () => (
    <Tag colorTheme={colorTheme} hierarchy='bold'>
      {tag}
    </Tag>
  )
  if (tooltipTitle || tooltipDescription) {
    return (
      <Tooltip title={tooltipTitle} description={tooltipDescription}>
        <TagElement />
      </Tooltip>
    )
  }
  return <TagElement />
}

export default OfferTag
