import { Box, Flex } from '@zenchef/styled-system/jsx'
import dynamic from 'next/dynamic'
import React from 'react'

import useOfferImage from '@/utils/hooks/useOfferImage'

// React.lazy is buggy with SSR in React 18, and this is a workaround.
// TODO: remove this when React version is upgraded to 19
const ArtNoSSR = dynamic(() => import('@zenchef/ds-react').then((mod) => mod.Art), { ssr: false })

export default function OfferImage({
  children,
  pictureUrl,
  height = '144px',
  rounded
}: {
  children?: React.ReactNode
  pictureUrl: string | null | undefined
  height?: string
  rounded?: boolean
}) {
  const { backgroundStyle, shouldDisplayPlaceholderImg } = useOfferImage(pictureUrl)

  return (
    <Box
      height={height}
      width='100%'
      flexShrink={0}
      borderTopRadius={rounded ? 'l' : 'unset'}
      backgroundPosition='center'
      backgroundColor='white'
      backgroundSize='cover'
      style={{
        backgroundImage: backgroundStyle
      }}
      position='relative'>
      {shouldDisplayPlaceholderImg ? (
        <Flex
          height='100%'
          width='100%'
          bgGradient='to-b'
          borderTopRadius={rounded ? 'l' : 'unset'}
          gradientFrom='background.neutral.base.bold/20'
          gradientTo='background.neutral.base.bold/0'
          color='content.brand.subtle'
          justifyContent='center'
          alignItems='center'>
          <ArtNoSSR name='Table' height='120px' width='120px' layout='square' />
        </Flex>
      ) : null}
      {children}
    </Box>
  )
}
